// import i18n from "../i18n";

export const dataSolutions = (translate) => {
  const data = [
    {
      icon: "code",
      title: translate("solutions[0].title"),
      text: translate("solutions[0].descr"),
    },
    {
      icon: "design",
      title: translate("solutions[1].title"),
      text: translate("solutions[1].descr"),
    },
    {
      icon: "cms",
      title: translate("solutions[10].title"),
      text: translate("solutions[10].descr"),
    },
    {
      icon: "process",
      title: translate("solutions[3].title"),
      text: translate("solutions[3].descr"),
    },
    {
      icon: "data",
      title: translate("solutions[5].title"),
      text: translate("solutions[5].descr"),
    },
    {
      icon: "bill",
      title: translate("solutions[6].title"),
      text: translate("solutions[6].descr"),
    },
    {
      icon: "calendar",
      title: translate("solutions[7].title"),
      text: translate("solutions[7].descr"),
    },
    {
      icon: "chat",
      title: translate("solutions[8].title"),
      text: translate("solutions[8].descr"),
    },
    {
      icon: "payment",
      title: translate("solutions[9].title"),
      text: translate("solutions[9].descr"),
    },
    {
      icon: "eshop",
      title: translate("solutions[4].title"),
      text: translate("solutions[4].descr"),
    },
    {
      icon: "moderation",
      title: translate("solutions[2].title"),
      text: translate("solutions[2].descr"),
    },
  ];
  return data;
};
