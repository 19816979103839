<template>
  <container id="portfolio" :title="t('title')" :subtitle="t('subtitle')" color="blue-light" links="true">
      <div  v-for="item in data" :key="item.logo" class="pf">
          <div class="pf__container">
            <div class="pf__img">
                <img :src="require(`@/assets/img/portfolio/${item.img.name}`)" :width="item.img.width" :height="item.img.height" :alt="item.img.alt" class="pf__img--img">
            </div>
            <div class="pf__icon">
                <img :src="require(`@/assets/img/portfolio/${item.logo.name}`)" :width="item.logo.width" :height="item.logo.height" :alt="item.logo.alt" class="pf__icon--img">
            </div>
            <div class="pf__content">
                <h2>
                    {{item.title}}
                </h2>
                <h3>
                    <div class="pf__content--logo">
                        <Svg icon="objective" className="pf__content--svg"/>
                    </div>
                    {{t('objective')}}
                </h3>
                <p>
                    {{item.objective}}
                </p>
                <h3>
                    <div class="pf__content--logo">
                        <Svg icon="techno" className="pf__content--svg"/>
                    </div>
                    {{t('techno')}}
                </h3>
                <ul>
                    <li v-for="i in item.techno" :key="i.icon">
                        <button class="pf__techno" @click="smoothScroll('solutions')">
                            <Svg :icon="i.icon" className="pf__techno--svg"/>
                            {{i.title}}
                        </button>
                    </li>
                </ul>
            </div>
          </div>
          <div class="pf__bottom">
              <div class="pf__bottom--item u-small-phone-hide">
                  {{item.status}}
              </div>
              <div class="pf__bottom--item">
                  <a :href="item.link" class="pf__link" target="_blank" rel="noopener">
                    <Svg icon="link" className="pf__link--svg"/>
                    {{item.linkName}}
                  </a>
              </div>
          </div>
      </div>
  </container>
</template>

<script>
import Container from './Container.vue';
import Svg from './utils/Svg';

import {useI18n} from 'vue-i18n';

import {dataSolutions} from '../composables/solutions';
import {smoothScroll} from '../composables/scroll';
import { computed } from 'vue';
export default {
  components: { Container, Svg },
  setup(){
        const {t} = useI18n({
            messages:{
                en: require('../locales/en/portfolio.json'),
                fr: require('../locales/fr/portfolio.json')
            },
        })


    let data = computed(() => {
        const sol = dataSolutions(t)
        let i = [
        {
            img: {
                name:"hiking-ul.webp",
                alt:"man hiking the trolltunga hike",
                width:"250",
                height:"410",
                
            },
            logo:{
                name: "hoe-light.webp",
                alt:"hike on earth logo",
                width:"200",
                height:"200",
                
            },

            title: t('portfolio[0].title'),
            objective: t('portfolio[0].objective'),
            techno:["design", "code", "data", "moderation", "chat", "eshop"],
            link:"https://www.hikeonearth.com",
            linkName:"hikeonearth.com",
            status: t('portfolio[0].date'),
            
        },
        {
            img: {
                name:"pad.webp",
                width:"300",
                height:"399",
                alt:"women standing in front of a pump and drink system"
                
            },
            logo:{
                name: "pad-logo.webp",
                width:"250",
                height:"126",
                alt:"pump and drink logo"
                
            },
            title: t('portfolio[4].title'),
            objective: t('portfolio[4].objective'),
            techno:["design", "code"],
            link:"https://www.pumpanddrink.com",
            linkName:"pumpanddrink.com",
            status: t('portfolio[4].date')
        },
        {
            img: {
                name:"philippe-img.webp",
                width:"300",
                height:"436",
                alt:"petit chaperon rouge Philippe Brasseur"
                
            },
            logo:{
                name: "philippe-logo.webp",
                width:"250",
                height:"88",
                alt:"philippe brasseur logo"
                
            },
            title: t('portfolio[1].title'),
            objective: t('portfolio[1].objective'),
            techno:["design", "code", "process", "calendar", "payment"],
            link:"https://www.philippebrasseur.be",
            linkName:"philippebrasseur.be",
            status: t('portfolio[1].date')
        },
        {
            img: {
                name:"zappy-ul.webp",
                width:"250",
                height:"375",
                alt:"crayon sur feuille"
                
            },
            logo:{
                name: "zs-light.webp",
                width:"200",
                height:"200",
                alt:"logo zappyschool"
                
            },
            title: t('portfolio[2].title'),
            objective: t('portfolio[2].objective'),
            techno:["design", "code", "process", "bill", "payment"],
            link:"http://www.zappyschool.com",
            linkName:"zappyschool.com",
            status: t('portfolio[2].date')
        },
        {
            img: {
                name:"delouvien-img.webp",
                width:"400",
                height:"485",
                alt:"image marteau avocat"
            },
            logo:{
                name: "delouvien.webp",
                width:"64",
                height:"64",
                alt:"logo avocat william delouvien"
            },
            title: t('portfolio[3].title'),
            objective: t('portfolio[3].objective'),
            techno:["design", "code"],
            link:"https://www.delouvien.be",
            linkName:"delouvien.be",
            status: t('portfolio[3].date')
        },
        
    ];
    i = i.map(el=>{
        el.techno = el.techno.map(it=>{
            it = sol.filter(f => f.icon === it )[0]
            return it
        })
        return el
    })
    return i
    })

    
    return {data, smoothScroll, t}
  }

}
</script>

<style lang="scss">
@import '../style/main';

$padding-content: 2rem;

.pf{
    position: relative;
    width: 100%;
    box-shadow: $shadow;
    border-radius: 5px;
    overflow: hidden;

    &:not(:last-child){
        margin-bottom: 4rem;
    }
    &__link{
        @include buttonNoStyle;

        display: flex;
        align-items: center;

        &--svg{
            @include svg(1.6rem);
            margin-right: 1rem;

        }
    }

    &__container{
        padding: $padding-content;
        background-color:$color-white;
        display: flex;  

        @include respond(phone){
            flex-direction: column;
        }
    }

    &__content{
        & h2{
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: .2rem;
        }
        & h3{
            margin: 2rem 0 1.5rem 0; 
            display: flex;
            align-items: center;
            font-weight: 400;
        }

        &--logo{
            display: flex;
            align-items: center;
            justify-content: center;
            color:$color-white;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: rgba($color-blue-main, .7);
            margin-right: 1rem

        }

        & ul {
            display: flex;
            flex-wrap: wrap;
        }

        & li{
            list-style: none;
             &:not(:last-child){
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
        }

        &--svg{
            @include svg(1.6rem);
        }
    }

    &__techno{
        @include buttonNoStyle;
        padding: .6rem 1.4rem;
        border: 1px solid currentColor;
        box-shadow: 3px 3px 0 currentColor;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: .1rem;
        border-radius: 3px;

        transition: all .2s;

       
        &--svg{
            @include svg(2rem);
            margin-right: 1.4rem;
        }

        &:hover{
            transform: translateY(-.4rem);
        }
    }

    &__img{
        flex: 0 0 20%;
        margin-right: 2rem;

        @include respond(phone){
            margin-right: 0;
            margin-bottom: 2rem;
        }

        &--img{
            width: 100%;
            height: auto;
        }
    }

    &__icon{
        position: absolute;
            right: $padding-content;
            top: $padding-content;

        @include respond(phone){
            right: calc(#{$padding-content} + 1rem);
            top: calc(#{$padding-content}  + 1rem);
        }
        &--img{
            height: 4rem;
            width: auto;
        }
    }
    &__bottom{
        text-transform: uppercase;
        letter-spacing: .1rem;

        background-color: $color-blue-main;
        color:$color-white;
        display: flex;
        justify-content: space-between;
        padding: 1rem 2rem;

    }
}

</style>